.containers {
  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 80%;
  /* Border */
  border: 4px dashed rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}
.react-pdf__Page.prevPage {
  position: absolute !important;
  z-index: 1;
}

.react-pdf__Document {
}

canvas {
  height: 100% !important;
  width: 100% !important;
}
