@media only screen and (max-width: 600px) {
  .cover_container {
    display: flex;
    width: 100%;
    align-items: center;
    height: 500px;
  }

  .img_logo {
    display: block;
    position: relative;
    width: 200px;
    height: 100%;
  }

  .titreFicheSynthese {
    font-size: medium;
  }

  .titreBienTypography {
    font-size: small;
  }
  /*Big smartphones [426px -> 600px]*/
}
