.cover_container {
  display: flex;
  width: 100%;
  align-items: center;
  height: 100%;
 
}
@media  screen and (max-width: 1000px){
  .cover_container {
    display: flex;
    width: 100%;
    align-items: center;
    height: 100%;
    justify-content: center;
    text-align: center;
    flex-direction: column;  
   
  }
}
.cover_media {
  width: 50%;

  position: relative;
}
.cover_logo {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

.cover_content {
}

@media only screen and (max-width: 600px) {
  .cover_container {
    display: flex;
    width: 100%;
    align-items: center;
    height: 500px;
  }

  .cover_logo {
    display: block;
    position: relative;
    width: 200px;
    height: 100%;
  }
  /*Big smartphones [426px -> 600px]*/
}
