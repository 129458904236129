@import url("~react-leaflet-markercluster/dist/styles.min.css");
@import "~react-user-guide/dist/custom-style.css";
@import "~intro.js/introjs.css";
@media screen and (max-width: 600px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

/* #root {
  background-color: #f5f5f5;

  height: 100vh;
} */

@media print {
  .container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0;
    padding-right: 0;
    size: landscape;
  }
  .leaflet-control-zoom {
    display: none;
  }
}

@media print {
  @page {
    size: landscape;
  }
}

.hidden {
  display: hidden;
}

.container {
  max-width: 100% !important;
  padding-top: 20px;

  padding-bottom: 120px;
  min-height: 100vh;
}

#mapid {
  height: 700px;
}

.leaflet-div-icon-polygon-name {
  background-color: transparent;
  border: none;
  line-height: 14px;
  font-size: 14px;
  font-weight: bolder;
  text-transform: uppercase;
  color: #122659;
  width: 170px !important;
  text-align: center;
  left: -85px;
}
@media only screen and (min-width: 600px) {
  .OnlyDesktopAppliquerNone {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .OnlyDesktopAppliquerBlock {
    display: block;
  }
}
.leaflet-div-icon-polygon-name-iris {
  background-color: transparent;
  border: none;
  line-height: 12px;
  font-size: 12px;
  font-weight: bolder;
  text-transform: capitalize;
  color: #122659;
  width: 90px !important;
  text-align: center;
  left: -45px;
}
canvas {
  max-height: 700px;
  /* margin-left: 40%; */
}
