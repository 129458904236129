body {
  margin: 0;
  padding: 0;
  min-height: 100vh !important;
  background-color: #f5f5f5 !important;
}

@media print { 
  * {
    -webkit-transition: none !important;
    transition: none !important;
  }
}

@media screen and (min-width: 0px) and (max-width: 599px) {
  html {
    font-size: 80%
  }
}
@media screen and (min-width: 600px) and (max-width: 959px) {
  html {
    font-size: 90%
  }
}
@media screen and (min-width: 960px) and (max-width: 1279px) {
  html {
    font-size: 100%
  }
}
@media screen and (min-width: 1280px) {
  html {
    font-size: 110%
  }
}
