.head_title {
  border: solid 1px #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  padding: 10px;
  width: fit-content;
  border-radius: 12px;
  background-color: rgba(12, 4, 93, 0.4);
  color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.image_principale {
  width: 80%;
  height: 300px;

  background-color: rgba(12, 4, 93, 0.4);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.images {
  display: block;
  width: 100%;
  height: 100%;

  background-color: rgba(12, 4, 93, 0.4);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.print-header {
  width: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .images {
    display: block;
    width: 100%;
    height: 100%;

    background-color: rgba(12, 4, 93, 0.4);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
  /*Big smartphones [426px -> 600px]*/
}
