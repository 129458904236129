@media print {
  @page {
    margin-top: 0;
    margin-bottom: 0;
  }
  body {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .Chartcard {
    border-style: solid;
    border-color: #f5f5f5;
    width: 100%;
    height: 50%;
  }
}
